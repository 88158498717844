import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const AnimatedLogo: React.FC = () => {
  const [frameIndex, setFrameIndex] = useState(1);
  const [isLoaded, setIsLoaded] = useState(false);
  const totalFrames = 16;
  const frameInterval = 100; // milliseconds
  const frames = useRef<string[]>([]);

  useEffect(() => {
    const preloadImages = async () => {
      const promises = [];
      for (let i = 1; i <= totalFrames; i++) {
        promises.push(
          fetch(`/frames/${i}.svg`)
            .then(response => response.text())
            .then(svg => {
              const base64 = `data:image/svg+xml;base64,${btoa(svg)}`;
              frames.current[i - 1] = base64;
            })
        );
      }
      await Promise.all(promises);
      setIsLoaded(true);
    };

    preloadImages();
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    const interval = setInterval(() => {
      setFrameIndex((prevIndex) => (prevIndex % totalFrames) + 1);
    }, frameInterval);

    return () => clearInterval(interval);
  }, [isLoaded]);

  if (!isLoaded) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
        <Typography color="secondary" variant='h5' sx={{ pt: 0.5, mt: 50 }}>
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="animate__animated animate__rubberBand" id='animatedLogo'>
      <Box className="animate__animated animate__bounceInDown">
        <img
          src={frames.current[frameIndex - 1]}
          alt={`Frame ${frameIndex}`}
        />
      </Box>
    </Box>
  );
};

export default AnimatedLogo;