import React, { useState } from 'react';
import { Box, Button, Typography, IconButton, Stack, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AboutView from './About';
import TokenomicsView from './Tokenomics';
import HowToBuyView from './HowToBuy';
import RoadmapView from './Roadmap';
import ContactView from './Contact';


const FullScreenView: React.FC = () => {

    const sections = [
        <AboutView/>,
        <TokenomicsView />,
        <HowToBuyView />,
        <RoadmapView />,
        <ContactView />,
    ];

    const sectionNames = [
        "About",
        "Tokenomics",
        "How to buy",
        "Roadmap",
        "Contact"
    ]

    const [isFullScreen, setIsFullScreen] = useState(false);
    const [index, setIndex] = useState<number>(0);

    const increaseIndex = () => {
        if (index === sectionNames.length -1) {
            setIndex(0)
        } else {
            setIndex(index+1);
        }
    }

    const decreaseIndex = () => {
        if (index === 0) {
            setIndex(sectionNames.length -1)
        } else {
            setIndex(index-1);
        }
    }

    const getNextSectionName = () => {
        if (index === sectionNames.length -1) {
            return sectionNames[0]
        } else {
            return sectionNames[index+1];
        }
    }

    const getPreviousSectionName = () => {
        if (index === 0) {
            return sectionNames[sectionNames.length -1]
        } else {
            return sectionNames[index-1];
        }
    }


    const handleOpen = () => {
        setIsFullScreen(true);
    };

    const handleClose = () => {
        setIsFullScreen(false);
    };

    return (
        <div>
            <Stack direction="column">
                {sectionNames.map((name, i) => (
                    <Button sx={{ mb: 2 }} onClick={() => {handleOpen();setIndex(i)}}>
                    <Typography variant="h4" color={"Menu"} className="text-meme">
                        {name}
                    </Typography>
                </Button>
                ))}
            </Stack>
            {isFullScreen && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: "#ffcc55",
                        zIndex: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 2,
                        boxSizing: 'border-box',
                    }}
                >
                    <IconButton
                        sx={{ position: 'absolute', top: 16, right: 16, zIndex:9}}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Chip
                        sx={{
                            fontSize: "1.3rem",
                            color: "secondary",
                            border:"2px solid #000",
                            position: 'absolute',
                            bottom: 30,
                            left: "15%",
                            zIndex:9,
                            pl: 1,
                        }}
                        label={"< " + getPreviousSectionName()}
                        component="button"
                        color="primary"
                        size='medium'
                        onClick={decreaseIndex}
                        clickable
                    />
                    <Chip
                        sx={{
                            fontSize: "1.3rem",
                            color: "secondary",
                            border:"2px solid #000",
                            position: 'absolute',
                            bottom: 30,
                            right: "15%",
                            zIndex:9,
                            pl: 1,
                        }}
                        // icon={<ChevronRightIcon/>}
                        label={getNextSectionName() + "         >"}
                        component="button"
                        color="primary"
                        size='medium'
                        onClick={increaseIndex}
                        clickable
                    />
                    {sections[index]}
                </Box>
            )}
        </div>
    );
};

export default FullScreenView;