import React from 'react';
import { Box, Typography } from '@mui/material';

const RoadmapView: React.FC = () => {

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: "#b700ff",
                    zIndex: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 2,
                    boxSizing: 'border-box',
                }}
            >
                 <Box className="animate__animated  animate__bounceInDown" sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <Typography variant="h2" component="h1" gutterBottom sx={{ mb: 2, color: "white" }} className='text-meme'>
                        Roadmap
                    </Typography>
                    <Typography variant="body1">
                        This is the content of the fullscreen view.
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default RoadmapView;